import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Grid from "../../styles/Grid";
import { fontFG20l, fontFG52m, fontFG46m, fontR16r } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";

const Figure = styled.div`
  ${fontFG46m};
  margin-bottom: 10px;
`;

const Description = styled.div`
  ${fontR16r}
`;

const StatisticsItem = styled.div`
  background-color: ${colors.green};
  color: ${colors.white};
  padding: 38px 0;
  border-radius: 6px;
`;

const Statistics = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;

  ${StatisticsItem} {
    width: 100%;
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;

    ${StatisticsItem} {
      width: 268px;
      margin: 0 8px 16px;
      &:first-child {
        margin: 0 50px 16px;
      }
    }
  }
`;

const HeaderTitle = styled.h1`
  ${fontFG52m};
  text-align: center;
  padding: 40px 0;
`;

const InterimTitle = styled.h2`
  ${fontFG46m};
  margin-bottom: 30px;
`;

const InterimBrief = styled.div`
  ${fontFG20l};
`;

const Interim = styled.div``;

const Image = styled.div``;

const Container = styled(Grid)`
  ${Image} {
    grid-column: 1 / span 6;
    margin-bottom: 80px;
  }
  ${Interim} {
    margin-bottom: 40px;
    grid-column: 2 / span 4;
  }
  ${Statistics} {
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${HeaderTitle} {
      grid-column: 4 / span 8;
    }
    ${Image} {
      grid-column: 1 / span 14;
      margin-bottom: 120px;
    }
    ${Interim} {
      margin-bottom: 0;
      grid-column: 2 / span 6;
    }
    ${Statistics} {
      grid-column: 8 / span 6;
    }
  }
`;

const HeaderSection = ({
  title,
  interimTitle,
  interimBrief,
  image,
  statistics,
}) => {
  const imageData = getImage(image);
  return (
    <Container>
      <HeaderTitle>{title}</HeaderTitle>
      {imageData && (
        <Image>
          <GatsbyImage image={imageData} alt={image.alt} />
        </Image>
      )}
      <Interim>
        <InterimTitle>{interimTitle}</InterimTitle>
        <InterimBrief>{interimBrief}</InterimBrief>
      </Interim>
      <Statistics>
        {statistics.map((statistic, index) => (
          <StatisticsItem key={index}>
            <Figure>{statistic.number}</Figure>
            <Description>{statistic.description}</Description>
          </StatisticsItem>
        ))}
      </Statistics>
    </Container>
  );
};

export default HeaderSection;
