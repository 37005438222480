import React from "react";
import styled, { css } from "styled-components";
import Grid from "../../styles/Grid";
import { fontFG46m, fontR14r, fontFG24m, fontFG20l } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";

const Title = styled.h2`
  ${fontFG46m};
  margin-bottom: 56px;
  text-align: center;
`;

const Year = styled.div`
  ${fontFG24m};
  margin-bottom: 8px;
`;

const Month = styled.div`
  ${fontFG20l};
  margin-bottom: 8px;
  text-transform: uppercase;
`;

const Description = styled.div`
  ${fontR14r};
  margin-bottom: 16px;
`;

const TimelineItem = styled.div`
  background-color: ${colors.white};
  margin: 0 20px;
`;

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${colors.green};
`;

const Timeline = styled(Grid)`
  position: relative;

  ${Dot} {
    grid-column: 1 / span 6;
    position: absolute;
    top: 8px;
  }
  @media (min-width: ${breakpoints.lg}) {
    ${Dot} {
      grid-column: 2 / span 12;
      left: 50%;
      transform: translate(-50%);
    }
  }
`;

const Axis = styled.div`
  border-left: 5px solid rgba(0, 202, 127, 0.25);
`;

const TextContainer = styled.div`
  position: relative;

  ${Axis} {
    position: absolute;
    height: 100%;
    transform: translateX(8px);
  }
  @media (min-width: ${breakpoints.lg}) {
    ${Axis} {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const dashStyle = css`
  content: "";
  position: absolute;
  border-top: 1px dashed ${colors.green};
  z-index: -1;
  top: 16px;
`;

const Container = styled(Grid)`
  ${Timeline} {
    grid-column: 1 / span 1;

    ${TimelineItem} {
      grid-column: 3 / span 3;
      text-align: left;
      ${Year} {
        ::before {
          ${dashStyle};
          width: 70px;
          left: 0;
        }
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 120px;

    ${Timeline}:nth-child(even) {
      grid-column: 2 / span 12;

      ${TimelineItem} {
        grid-column: 4 / span 3;
        text-align: right;
        ${Year} {
          ::before {
            width: 0;
          }
          ::after {
            ${dashStyle};
            width: 100px;
            left: 50%;
            transform: translate(-100%, 100%);
          }
        }
      }
    }
    ${Timeline}:nth-child(odd) {
      grid-column: 2 / span 12;

      ${TimelineItem} {
        grid-column: 9 / span 3;
        text-align: left;
        ${Year} {
          ::before {
            ${dashStyle};
            width: 100px;
            left: 50%;
            transform: translate(10%, 100%);
            overflow: hidden;
          }
        }
      }
    }
  }
`;

const TimelineSection = ({ title, timelines }) => {
  // Timelines data structure [{year, month, description}, {y,m,d}, ...]
  // Get only the year in timelines: [2016, 2016, 2017...]
  const years = timelines.map((timeline) => timeline.year);
  // Filter out same year value: [2016, 2017, 2018, 2019]
  const uniqueYears = years.filter(
    (year, index) => years.indexOf(year) === index
  );
  // Group timelines by unique year in array: [[{2016, m, d}, {2016, m, d}, ...], [{2017, m, d}, {2017, m, d}, ...]]
  const timelineItemsInYear = uniqueYears.map((year) => {
    return timelines.filter((timeline) => timeline.year === year);
  });
  return (
    <Container>
      <Title>{title}</Title>
      <TextContainer>
        <Axis />
        {timelineItemsInYear.map((timeline, index) => (
          <Timeline key={index}>
            <TimelineItem>
              <Year>{timeline[0].year}</Year>
              {timeline.map((item, index) => (
                <>
                  <Month>{item.month}</Month>
                  <Description>{item.description}</Description>
                </>
              ))}
            </TimelineItem>
            <Dot />
          </Timeline>
        ))}
      </TextContainer>
    </Container>
  );
};

export default TimelineSection;
