import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Grid from "../../styles/Grid";
import { fontFG46m, fontR14r } from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";
import RawHTML from "../utilities/RawHTML";

const Title = styled.h2`
  ${fontFG46m};
  margin-bottom: 24px;
`;

const Brief = styled(RawHTML)`
  ${fontR14r};
  > p {
    margin-bottom: 24px;
  }
`;

const TextContainer = styled.div``;

const Image = styled.div``;

const Container = styled(Grid)`
  padding: 80px 0;
  ${TextContainer} {
    grid-column: 2 / span 4;
    margin-bottom: 32px;
  }
  @media (min-width: ${breakpoints.lg}) {
    align-items: center;
    ${TextContainer} {
      grid-row: 1;
      grid-column: 8 / span 6;
      margin-bottom: 0;
    }
    ${Image} {
      grid-row: 1;
      grid-column: 2 / span 6;
    }
  }
`;

const WhatWeDoSection = ({ title, brief, image }) => {
  const imageData = getImage(image);
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Brief>{brief.html}</Brief>
      </TextContainer>
      {imageData && (
        <Image>
          <GatsbyImage image={imageData} alt={image.alt} />
        </Image>
      )}
    </Container>
  );
};

export default WhatWeDoSection;
