import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { fontFG40m, fontFG30m, fontR14r } from "../../styles/fonts";
import Grid from "../../styles/Grid";
import breakpoint from "../../styles/breakpoints";
import colors from "../../styles/colors";

const Title = styled.h2`
  ${fontFG40m}
`;

const ValueTitle = styled.h3`
  ${fontFG30m};
`;

const ValueBrief = styled.div`
  ${fontR14r};
`;

const Value = styled.div``;

const Values = styled.div``;

const Image = styled.div``;

const Container = styled(Grid)`
  padding: 90px 0 0;
  background-color: ${colors.background};

  ${Title} {
    margin-bottom: 22px;
  }

  ${Values} {
    padding-bottom: 90px;
    grid-column: 2 / span 4;

    ${Value} {
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      ${ValueTitle} {
        margin-bottom: 24px;
      }
    }
  }

  ${Image} {
    display: none;
  }

  @media (min-width: ${breakpoint.lg}) {
    padding: 120px 0 0;

    ${Title} {
      margin-bottom: 32px;
    }

    ${Values} {
      padding-bottom: 120px;
      grid-column: 2 / span 8;
      display: grid;
      grid-column-gap: 32px;
      grid-row-gap: 56px;
      grid-template-columns: 1fr 1fr;

      ${Value} {
        margin: 0;
      }
    }

    ${Image} {
      display: block;
      grid-column: 10 / span 4;
    }
  }
`;

const ValuesSection = ({ title, image, values }) => {
  const imageData = getImage(image);
  return (
    <Container>
      <Title>{title}</Title>
      <Values>
        {values.map((value, index) => (
          <Value key={index}>
            <ValueTitle>{value.title}</ValueTitle>
            <ValueBrief>{value.brief}</ValueBrief>
          </Value>
        ))}
      </Values>
      {imageData && (
        <Image>
          <GatsbyImage image={imageData} alt={image.alt} />
        </Image>
      )}
    </Container>
  );
};

export default ValuesSection;
