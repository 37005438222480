import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import HeaderSection from "../components/about/HeaderSection";
import WeDoSection from "../components/about/WhatWeDoSection";
import TimelineSection from "../components/about/TimelineSection";
import ValuesSection from "../components/about/ValuesSection";
import TeamSection from "../components/about/TeamSection";
import LocationSection from "../components/about/LocationSection";
import Slices from "../components/shared/Slices";

class AboutPage extends Component {
  // componentDidMount() {
  //   window.analytics.page("About");
  // }

  render() {
    const {
      location,
      data,
      pageContext: { locale },
    } = this.props;
    const pageData = data.prismicAboutPage.data;
    // TODO: add slice query in pageData - remove deprecated rawData
    const rawData = data.prismicAboutPage.dataRaw;

    return (
      <Layout location={location} locale={locale} pageData={pageData}>
        <Seo
          title={`${pageData.page_title} | Workmate`}
          description={pageData.interim_brief}
          image={pageData.we_do_image.url}
        />
        <HeaderSection
          title={pageData.header_title}
          interimTitle={pageData.interim_title}
          interimBrief={pageData.interim_brief}
          image={pageData.header_image}
          statistics={pageData.statistics}
        />
        <WeDoSection
          title={pageData.we_do_title}
          brief={pageData.we_do_brief}
          image={pageData.we_do_image}
        />
        <TimelineSection
          title={pageData.timeline_title}
          timelines={pageData.timelines}
        />
        <TeamSection title={pageData.team_title} team={pageData.team} />
        <ValuesSection
          title={pageData.values_title}
          image={pageData.values_image}
          values={pageData.values}
        />
        <LocationSection
          title={pageData.locations_title}
          locations={pageData.locations}
        />
        <Slices slices={rawData.body} />
      </Layout>
    );
  }
}

export default AboutPage;

export const query = graphql`
  query AboutQuery($localeKey: String) {
    prismicAboutPage(lang: { eq: $localeKey }) {
      dataRaw
      data {
        page_title
        navigation_cta_button_text
        navigation_cta_button_url
        header_title
        header_image {
          alt
          gatsbyImageData
        }
        interim_title
        interim_brief
        we_do_title
        we_do_brief {
          html
        }
        we_do_image {
          alt
          url
          gatsbyImageData
        }
        statistics {
          number
          description
        }
        timeline_title
        timelines {
          year
          month
          description
        }
        values_title
        values_image {
          alt
          gatsbyImageData
        }
        values {
          title
          brief
        }
        team_title
        team {
          name
          title
          brief
          image {
            alt
            gatsbyImageData(width: 353, height: 353)
          }
        }
        locations_title
        locations {
          city
          address {
            html
          }
          image {
            alt
            gatsbyImageData
          }
        }
        cta_main_title
        cta_brief
        link_title_1
        link_message_1
        link_title_2
        link_message_2
      }
    }
  }
`;
