import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import { fontFG24m, fontFG40m, fontR14r, fontFG20l } from "../../styles/fonts";

const Title = styled.h2`
  ${fontFG40m};
  text-align: center;
`;

const Name = styled.div`
  ${fontFG24m};
  margin-bottom: 8px;
`;

const Position = styled.div`
  ${fontFG20l};
  margin-bottom: 12px;
`;

const Brief = styled.div`
  ${fontR14r};
`;

const TextContainer = styled.div``;

const Member = styled.div``;

const Members = styled.div``;

const Container = styled(Grid)`
  padding-top: 90px;

  ${Title} {
    margin-bottom: 60px;
  }

  ${Members} {
    grid-column: 2 / span 4;
    margin-bottom: 60px;

    ${Member} {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 120px;

    ${Title} {
      grid-column: 2 / span 12;
      margin-bottom: 90px;
    }

    ${Members} {
      grid-column: 2 / span 12;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 90px;
      margin-bottom: 90px;

      ${Member} {
        width: 100%;
      }
    }
  }
`;

const TeamSection = ({ title, team }) => (
  <Container>
    <Title> {title} </Title>
    <Members>
      {team.map((member) => {
        const imageData = getImage(member.image);
        return (
          <Member key={member.name}>
            {imageData && (
              <GatsbyImage
                style={{ marginBottom: 30 }}
                image={imageData}
                alt={member.image.alt}
              />
            )}
            <TextContainer>
              <Name>{member.name}</Name>
              <Position>{member.title}</Position>
              <Brief>{member.brief}</Brief>
            </TextContainer>
          </Member>
        );
      })}
    </Members>
  </Container>
);

export default TeamSection;
