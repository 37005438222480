import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RawHTML from "../utilities/RawHTML";
import Grid from "../../styles/Grid";
import breakpoints from "../../styles/breakpoints";
import { fontFG30m, fontFG40m, fontR14r } from "../../styles/fonts";

const Title = styled.div`
  ${fontFG40m};
`;

const City = styled.div`
  ${fontFG30m};
  margin-bottom: 24px;
`;

const Address = styled(RawHTML)`
  ${fontR14r};
`;

const Image = styled(GatsbyImage)`
  margin-bottom: 30px;
`;

const Location = styled.div``;

const Locations = styled.div``;

const Container = styled(Grid)`
  padding: 90px 0;

  ${Title} {
    margin-bottom: 22px;
  }

  ${Locations} {
    ${Location} {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 120px 0;

    ${Title} {
      grid-column: 2 / span 12;
      margin-bottom: 48px;
    }

    ${Locations} {
      grid-column: 2 / span 12;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 95px;

      ${Location} {
        margin-bottom: 0;
      }
    }
  }
`;

const LocationSection = ({ title, locations }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Locations>
        {locations.map((location, index) => {
          const imageData = getImage(location.image);
          return (
            <Location key={index}>
              {imageData && (
                <Image image={imageData} alt={location.image.alt} />
              )}
              <City>{location.city}</City>
              <Address>{location.address.html}</Address>
            </Location>
          );
        })}
      </Locations>
    </Container>
  );
};

export default LocationSection;
